import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import qs from 'qs';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSortNumericDown, faSortAmountDown, faQuoteRight, faAlignLeft,
} from '@fortawesome/free-solid-svg-icons';

import './SearchView.css';
import {
  Card, Form, Button, InputGroup, ButtonGroup,
} from 'react-bootstrap';

export default function SearchBox(props) {
  const { isSearching, onSearch } = props;

  const params = getSearchParams(props);

  const [keyword, setKeyword] = useState(params.keyword);
  const [orderBy, setOrderBy] = useState(params.orderBy);
  const [searchType, setSearchType] = useState(params.searchType);

  const onSearchClicked = () => {
    onSearch({
      keyword, orderBy, searchType, page: 0,
    });
  };

  const isDesktopSize = window.innerWidth > 700;

  return (
    <Card>
      <Card.Body>
        <InputGroup className="mb-3">
          <Form.Control
            value={keyword}
            as="input"
            type="text"
            placeholder="Søk..."
            aria-describedby="basic-addon2"
            onChange={(event) => setKeyword(event.target.value)}
          />
          <InputGroup.Append>
            <Button variant="outline-secondary" loading={isSearching} onClick={onSearchClicked}>Søk</Button>
          </InputGroup.Append>
        </InputGroup>

        <div style={{ marginTop: '10px' }}>
          <ButtonGroup>
            <Button variant="outline-dark" icon onClick={() => setOrderBy('SCORE')} active={orderBy === 'SCORE'}>
              <FontAwesomeIcon icon={faSortNumericDown} />
              {isDesktopSize ? 'Beste treff' : null}
            </Button>
            <Button variant="outline-dark" onClick={() => setOrderBy('INDEX')} active={orderBy === 'INDEX'}>
              <FontAwesomeIcon icon={faSortAmountDown} />
              {isDesktopSize ? 'Kronologisk' : null}
            </Button>
          </ButtonGroup>

          <ButtonGroup style={{ marginLeft: '10px' }}>
            <Button variant="outline-dark" onClick={() => setSearchType('PHRASE')} active={searchType === 'PHRASE'}>
              <FontAwesomeIcon icon={faQuoteRight} />
              {isDesktopSize ? 'Dette nøyaktige sitatet' : null}
            </Button>
            <Button variant="outline-dark" onClick={() => setSearchType('WORDS')} active={searchType === 'WORDS'}>
              <FontAwesomeIcon icon={faAlignLeft} />
              {isDesktopSize ? 'Alle ordene' : null}
            </Button>
          </ButtonGroup>
        </div>
      </Card.Body>
    </Card>
  );
}

SearchBox.propTypes = {
  isSearching: PropTypes.bool.isRequired,
  onSearch: PropTypes.func.isRequired,
};


function getSearchParams(props) {
  const searchParams = qs.parse(props.location.search, { ignoreQueryPrefix: true });
  return {
    keyword: '', orderBy: 'SCORE', searchType: 'PHRASE', ...searchParams,
  };
}
