import React from 'react';
import PropTypes from 'prop-types';

import { withRouter } from 'react-router-dom';
import { Button, ButtonGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';

function ChapterControls({ currentChapter, chapters, history }) {
  const onNext = () => {
    if (!currentChapter) return;
    const indexOfCurrentChapter = chapters.map((e) => e.id).indexOf(currentChapter.id);

    if (indexOfCurrentChapter === chapters.length - 1) {
      return;
    }

    const nextChapter = chapters[indexOfCurrentChapter + 1];
    history.push(`?ref=${nextChapter.reference}`);
  };

  const onPrev = () => {
    if (!currentChapter) return;
    const indexOfCurrentChapter = chapters.map((e) => e.id).indexOf(currentChapter.id);

    if (indexOfCurrentChapter === 0) {
      return;
    }

    const previousChapter = chapters[indexOfCurrentChapter - 1];
    history.push(`?ref=${previousChapter.reference}`);
  };

  const isDesktopSize = window.innerWidth > 700;

  return (
    <ButtonGroup>

      <Button variant="outline-dark" onClick={() => onPrev()}>
        <FontAwesomeIcon icon={faArrowLeft} />
        {isDesktopSize ? 'Forrige' : null}
      </Button>
      <Button variant="outline-dark" onClick={() => onNext()}>
        <FontAwesomeIcon icon={faArrowRight} />
        {isDesktopSize ? 'Neste' : null}
      </Button>
    </ButtonGroup>
  );
}

ChapterControls.propTypes = {
  currentChapter: PropTypes.objectOf().isRequired,
  chapters: PropTypes.arrayOf().isRequired,
};

export default withRouter(ChapterControls);
