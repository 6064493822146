import React from 'react';
import { Tab, Table } from 'react-bootstrap';

export default function CollectionTab(props) {
  const {
    collection, books, history, key,
  } = props;

  const rows = books.map((e) => {
    const link = `/c/${e.collectionId}?ref=${e.abbreviation}`;
    return (
      <tr onClick={() => history.push(link)}>
        <td>{e.title}</td>
        <td>{e.abbreviation}</td>
      </tr>
    );
  });

  return (
    <Tab eventKey={key} title={collection.title}>
      <Table striped bordered hover size="sm">
        <thead>
          <tr>
            <th>Tittel</th>
            <th>Referanse</th>
          </tr>
        </thead>
        <tbody>
          {rows}
        </tbody>
      </Table>
    </Tab>
  );
}
