import React from 'react';
import PropTypes from 'prop-types';

import { withRouter } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

function PreviousChapterView({ currentChapter, chapters, history }) {
  const onClick = () => {
    if (!currentChapter) return;
    const indexOfCurrentChapter = chapters.map((e) => e.id).indexOf(currentChapter.id);

    if (indexOfCurrentChapter === 0) {
      return;
    }

    const previousChapter = chapters[indexOfCurrentChapter - 1];
    history.push(`?ref=${previousChapter.reference}`);
  };

  return (
    <Button variant="outline-dark" style={{ float: 'left' }} onClick={() => onClick()}>
      <FontAwesomeIcon icon={faArrowLeft} />
      Forrige
    </Button>
  );
}

PreviousChapterView.propTypes = {
  currentChapter: PropTypes.objectOf().isRequired,
  chapters: PropTypes.arrayOf().isRequired,
};

export default withRouter(PreviousChapterView);
