import React from 'react';

/**
 * Context providing information about common data
 */
export const DataContext = React.createContext({ collections: [], books: [] });

export const BookViewContext = React.createContext({
  book: null,
  chapters: [],
  verses: [],
  targetChapter: null,
  targetVerse: null,
  targetChapterRef: null,
  targetVerseRef: null,
  targetBookRef: null,
  isLinksVisible: true,
  toggleIsLinksVisible: () => { },
});
