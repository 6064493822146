import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Tabs, Tab } from 'react-bootstrap';
import BrowserView from './BrowserView';

import { ChaptersRepo, BooksRepo } from '../../Api';

export default function Browser(props) {
  const [currentStep, setCurrentStep] = useState('collections');

  const [selectedCollection, setSelectedCollection] = useState(null);

  const [selectedBook, setSelectedBook] = useState(null);

  const [books, setBooks] = useState([]);
  const [chapters, setChapters] = useState([]);

  const { collections, onClose } = props;

  const collectionItems = collections.map((e) => ({
    id: e.id,
    title: e.title,
    subtitle: e.abbreviation,
    data: e,
  }));

  const bookItems = books.map((e) => ({
    id: e.id,
    title: e.title,
    subtitle: e.abbreviation,
    data: e,
  }));

  const chapterItems = chapters.map((e) => ({
    id: e.id,
    title: e.title,
    subtitle: e.description,
    data: e,
  }));

  const onCollectionSelected = (collection) => {
    setSelectedCollection(collection.data);
    setBooks([]);
    setCurrentStep('books');
    BooksRepo.getBooksByCollection(collection.data.id).then((result) => setBooks(result));
  };

  const onBookSelected = (book) => {
    setSelectedBook(book.data);
    setChapters([]);
    setCurrentStep('chapters');
    ChaptersRepo.getChapters(book.id).then((result) => setChapters(result));
  };

  const onChapterSelected = (chapter) => {
    const { collectionId } = selectedBook;
    props.history.push(`/c/${collectionId}?ref=${chapter.data.reference}`);
    onClose();
  };

  return (
    <div>
      <Tabs defaultActiveKey="books" activeKey={currentStep} onSelect={(key) => setCurrentStep(key)}>
        <Tab eventKey="collections" title="Velg samling">
          <BrowserView items={collectionItems} onClick={onCollectionSelected} />
        </Tab>
        <Tab eventKey="books" title="Velg bok">
          <BrowserView items={bookItems} onClick={onBookSelected} />
        </Tab>
        <Tab eventKey="chapters" title="Velg kapittel" disabled={!selectedBook}>
          <BrowserView items={chapterItems} onClick={onChapterSelected} />
        </Tab>
      </Tabs>
    </div>
  );
}

Browser.propTypes = {
  books: PropTypes.arrayOf(PropTypes.object).isRequired,
  onClose: PropTypes.func.isRequired,
};
