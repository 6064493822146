import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

export default class Books {
  static getBooks() {
    return new Promise((resolve, reject) => {
      const url = `${API_URL}/books`;

      axios
        .get(url)
        .then((result) => resolve(result.data))
        .catch((error) => reject(error));
    });
  }

  static getBook(bookId) {
    return new Promise((resolve, reject) => {
      const url = `${API_URL}/books/${bookId}`;
      axios
        .get(url)
        .then((result) => resolve(result.data))
        .catch((error) => reject(error));
    });
  }

  static getBookByRef(collectionId, ref) {
    return new Promise((resolve, reject) => {
      const url = `${API_URL}/collections/${collectionId}/books/${ref}`;

      axios
        .get(url)
        .then((result) => resolve(result.data))
        .catch((error) => reject(error));
    });
  }

  static getBooksByCollection(collectionId) {
    return new Promise((resolve, reject) => {
      const url = `${API_URL}/collections/${collectionId}/books/`;
      axios
        .get(url)
        .then((result) => resolve(result.data))
        .catch((error) => reject(error));
    });
  }
}
