import React from 'react';
import { withRouter } from 'react-router-dom';

import {
  Row, Col, Table, Tabs, Tab,
} from 'react-bootstrap';
import { useAuth } from '../../Auth/AuthProvider';
import CollectionTab from './CollectionTab';

function Home(props) {
  const containerStyle = { marginTop: '10px' };
  const { loading, user } = useAuth();

  const { books, collections } = props;

  const tabs = collections.map((col, index) => {
    const booksInCol = books.filter((e) => e.collectionId === col.id);
    return CollectionTab({
      ...props, collection: col, books: booksInCol, key: index,
    });
  });

  return (
    <div style={{ height: '100%' }}>
      <div style={containerStyle}>
        <div>
          <h2>
            Velkommen!
          </h2>
          <p>
            Denne versjonen av databibelen er utarbeidet av Samfundets Forlag.
            <br />
            Du er pålogget som
            {' '}
            {user ? user.email : null}
          </p>
        </div>
        <Tabs defaultActiveKey={0}>
          {tabs}
        </Tabs>
      </div>
    </div>
  );
}

export default withRouter(Home);
