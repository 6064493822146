import React from 'react';
import { withRouter, Link } from 'react-router-dom';

function ChaptersView({ chapters, currentChapter }) {
  const containerStyle = { marginRight: '4px', display: 'inline-block', fontSize: '18px' };
  return chapters.map((e, i) => {
    const style = currentChapter && currentChapter.id === e.id ? { fontWeight: 'bold', fontSize: '22px' } : null;
    return (
      <span style={{ ...containerStyle, ...style }}>
        <Link to={`?ref=${e.reference}`}>{i + 1}</Link>
      </span>
    );
  });
}

export default withRouter(ChaptersView);
