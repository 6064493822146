import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

import Wrapper from './Wrapper/Wrapper';

export default function App() {
  return (
    <div>
      <Router>
        <Wrapper />
      </Router>
    </div>
  );
}
