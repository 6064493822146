/* eslint-disable radix */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import qs from 'qs';

import { BooksRepo, ChaptersRepo, VersesRepo } from '../../Api/index';

import BookView from './BookView';
import { BookViewContext } from '../../Contexts/Contexts';
import Loading from '../../Auth/Loading';

function unwrapRef(ref) {
  if (!ref) return { bookRef: null, chapterRef: null, verseRef: null };

  const verseRegex = /([0-9]{0,2}\s)?([æøåÆØÅa-zA-Z]{1,6})( [0-9]{0,3}),([0-9]{0,3})\w/;
  const verseRefs = ref.match(verseRegex);

  const chapterRegex = /([0-9]{0,2}\s)?([æøåÆØÅa-zA-Z]{1,6})( [0-9]{0,3})\w/;
  const chapterRefs = ref.match(chapterRegex);

  const bookRegex = /([0-9]{0,2}\s)?([æøåÆØÅa-zA-Z]{1,6})\w/;
  const bookRefs = ref.match(bookRegex);

  return {
    bookRef: bookRefs ? bookRefs[0] : null,
    chapterRef: chapterRefs ? chapterRefs[0] : null,
    verseRef: verseRefs ? verseRefs[0] : null,
  };
}


export default function BookViewContainer(props) {
  const [book, setBook] = useState(null);
  const [chapters, setChapters] = useState([]);
  const [verses, setVerses] = useState([]);

  const [chapter, setChapter] = useState(null);
  const [verse, setVerse] = useState(null);

  const [isLinksVisible, setIsLinksVisible] = useState(true);

  const { collectionId } = props.match.params;
  const { ref } = qs.parse(props.location.search, { ignoreQueryPrefix: true });
  const { bookRef, chapterRef, verseRef } = unwrapRef(ref);

  // Fetch the book of the given bookRef.
  useEffect(() => {
    BooksRepo.getBookByRef(collectionId, bookRef).then((result) => setBook(result));
  }, [bookRef]);

  // Fetch the chapters of the given book.
  useEffect(() => {
    const fetchChapters = async () => {
      try {
        if (!book) return;
        const result = await ChaptersRepo.getChapters(book.id);
        setChapters(result);
        const currentChapter = result.find((e) => e.reference === chapterRef);
        setChapter(currentChapter || result[0]);
      } catch (error) {
        console.error(error);
      }
    };

    fetchChapters();
  }, [book]);

  // Fetch the verses of a given chapter.
  useEffect(() => {
    const fetchVerses = async () => {
      try {
        if (chapter) {
          setVerses([]);
          const result = await VersesRepo.getVerses(chapter.id);
          const currentVerse = result.find((e) => e.reference === verseRef);
          setVerses(result);
          setVerse(currentVerse);
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchVerses();
  }, [chapter]);

  // If the chapter reference updates, find the chapter.
  useEffect(() => {
    if (!book || !chapter) return;
    const currentChapter = chapters.find((e) => e.reference === chapterRef);
    setChapter(currentChapter || chapters[0]);
  }, [chapterRef]);

  // If the verse reference updates, find the verse.
  useEffect(() => {
    if (!book || !chapter) return;
    const currentVerse = verses.find((e) => e.reference === verseRef);
    setVerse(currentVerse);
  }, [verseRef]);


  // Listen to changes to the book, and update the document title
  useEffect(() => {
    document.title = book ? book.title : 'Bibelen';
  }, [book]);

  if (!book || !chapters) {
    return <Loading />;
  }

  const contextValue = {
    book,
    chapters,
    verses,
    targetChapter: chapter,
    targetVerse: verse,
    targetChapterRef: chapterRef,
    targetVerseRef: verseRef,
    targetBookRef: bookRef,
    isLinksVisible,
    toggleIsLinksVisible: () => setIsLinksVisible(!isLinksVisible),
  };

  return (
    <BookViewContext.Provider value={contextValue}>
      <BookView />
    </BookViewContext.Provider>
  );
}

BookViewContainer.propTypes = {};
