import React, { useEffect, useState } from 'react';

import { BrowserRouter as Router, Switch } from 'react-router-dom';

import { Container } from 'react-bootstrap';
import AppNavbar from '../Components/AppNavbar';

import BookViewContainer from '../Components/BookView/BookViewContainer';
import SearchView from '../Components/Search/SearchView';
import HomeContainer from '../Components/Home/HomeContainer';
import Welcome from '../Components/Welcome/Welcome';
import { DataContext } from '../Contexts/Contexts';
import { CollectionsRepo, BooksRepo } from '../Api';
import PrivateRoute from '../Auth/PrivateRoute';

export default function Wrapper(props) {
  const [books, setBooks] = useState([]);
  const [collections, setCollections] = useState([]);

  // CollectionID for the only collection in the application.
  const collectionId = 1;

  useEffect(() => {
    CollectionsRepo.getCollections().then((result) => setCollections(result));
    BooksRepo.getBooks(collectionId).then((result) => setBooks(result));
  }, []);

  return (
    <Router>
      <div>
        <AppNavbar />
        <DataContext.Provider value={{ books, collections }}>
          <Container>
            <Switch>
              <PrivateRoute path="/" exact component={HomeContainer} />
              <PrivateRoute path="/c/:collectionId" component={BookViewContainer} />
              <PrivateRoute path="/search" component={SearchView} />
              <PrivateRoute path="/about" component={Welcome} />
            </Switch>
          </Container>
        </DataContext.Provider>
      </div>
    </Router>
  );
}
