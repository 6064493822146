import React from 'react';
import { Link } from 'react-router-dom';

import './SearchView.css';
import { Card, Alert } from 'react-bootstrap';

export default function SearchResultsView(props) {
  const { searchResult } = props;

  if (!searchResult) return <NoSearch />;

  const {
    page, totalPages, totalCount, keyword, verses,
  } = searchResult;

  if (totalCount < 1) return <NoResults keyword={keyword} />;

  const views = verses.map((element) => {
    const link = `/c/${element.collectionid}?ref=${element.reference}`;
    return (
      <Card style={{ marginTop: '10px' }}>
        <Card.Body>
          <Link to={link}>
            <b>{element.reference}</b>
          </Link>
          <p>{element.text}</p>
        </Card.Body>
      </Card>
    );
  });

  const text = `Side ${page + 1} av ${totalPages + 1}. Totalt ${totalCount} treff for søkeordet `;

  return (
    <div style={{ height: '100%' }}>
      <p>
        {text}
        <b>{keyword}</b>
      </p>
      {views}
    </div>
  );
}


function NoSearch() {
  return (
    <Alert>
      <Alert.Heading>Søk</Alert.Heading>
      Søk blandt bibelvers. Les mer om søkefunksjonen her.
    </Alert>
  );
}

function NoResults({ keyword }) {
  return (
    <Alert>
      <Alert.Heading>Ingen resultater</Alert.Heading>
      Ingen treff for søkeordet
      {' '}
      <b>{keyword}</b>
    </Alert>
  );
}
