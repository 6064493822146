import React from 'react';
import ReactDOM from 'react-dom';

import { AuthProvider } from './Auth/AuthProvider';

import './index.css';

import App from './App';
import * as serviceWorker from './serviceWorker';


const onRedirectCallback = (appState) => {
  window.history.replaceState(
    {},
    document.title,
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname,
  );
};

ReactDOM.render(
  <AuthProvider
    domain="samfundet.eu.auth0.com"
    client_id="vn3T9Mrx6dc7uddHZg0bsJqUP7USH6SB"
    redirect_uri={window.location.origin}
    onRedirectCallback={onRedirectCallback}
  >
    <App />
  </AuthProvider>,
  document.getElementById('root'),
);

serviceWorker.unregister();
