import React from 'react';

import moment from 'moment';
import { Button } from 'react-bootstrap';

export default function NoAccess(props) {
  const { logout } = props;

  const style = {
    marginTop: '20px',
    justifyContent: 'center',
    alignItems: 'center',
  };


  const metaStyle = {
    margin: '0px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };

  return (
    <div>
      <div style={style}>
        <div>
          <h2>Det oppsto en feil!</h2>
          <p>
            For å kunne logge på tjenesten trenger du en gyldig
            {' '}
            <b>@samfundet.org</b>
            {' '}
            epostadresse.
            {' '}
            <br />
            F.eks.
            {' '}
            <b>@medlem.samfundet.org</b>
            {' '}
            eller
            {' '}
            <b>@elev.samfundet.org</b>
          </p>
          <Button variant="outline-primary" onClick={() => logout()}>
            Logg på med en annen konto
          </Button>
        </div>
      </div>
      <hr />
      <div style={metaStyle}>
        <p>
          Menigheten Samfundets Bibelverktøy -
          {' '}
          {moment().year()}
        </p>
      </div>
      <div style={metaStyle}>
        <p>
          Trender du hjelp?
          {' '}
          <b>post@samfundet.org</b>
        </p>
      </div>
    </div>
  );
}
