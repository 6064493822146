import React from 'react';

import moment from 'moment';
import { Button, Spinner } from 'react-bootstrap';
import Loading from './Loading';

export default function Login(props) {
  const { login, loading } = props;

  const style = {
    marginTop: '20px',
    justifyContent: 'center',
    alignItems: 'center',
  };

  const metaStyle = {
    margin: '0px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };

  if (loading) { return <Loading />; }

  return (
    <div>
      <div style={style}>
        <h2>Velkommen til Databibelen</h2>
        <p>
          For å kunne bruke tjenesten trenger du en gyldig
          {' '}
          <b>@samfundet.org</b>
          {' '}
          epostadresse.
          {' '}
          <br />
          F.eks.
          {' '}
          <b>@medlem.samfundet.org</b>
          {' '}
          eller
          {' '}
          <b>@elev.samfundet.org</b>
        </p>
        <Button variant="outline-primary" onClick={() => login()}>
          Klikk her for å logge inn
        </Button>
      </div>
      <hr />
      <div style={metaStyle}>
        <p>
          Menigheten Samfundets Bibelverktøy -
          {' '}
          {moment().year()}
        </p>
      </div>
      <div style={metaStyle}>
        <p>
          Trenger du hjelp?
          {' '}
          <b>post@samfundet.org</b>
        </p>
      </div>
    </div>
  );
}
