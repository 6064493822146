import React, { useEffect } from 'react';
import { Route } from 'react-router-dom';
import qs from 'qs';

import { useAuth } from './AuthProvider';
import Login from './Login';
import NoAccess from './NoAccess';


export default function PrivateRoute({
  component: Component, path, exact, ...rest
}) {
  const {
    loading, logout, isAuthenticated, loginWithRedirect,
  } = useAuth();

  const { error } = qs.parse(window.location.search, { ignoreQueryPrefix: true });

  const onLogin = async () => {
    await loginWithRedirect({
      appState: { targetUrl: path },
    });
  };

  const onLogout = async () => {
    await logout();
  };

  let render = <Login />;

  if (error) render = (props) => <NoAccess logout={onLogout} />;
  else if (!isAuthenticated) render = (props) => <Login login={onLogin} loading={loading} />;
  else render = (props) => <Component {...props} />;

  return <Route path={path} exact={exact} render={render} {...rest} />;
}
