import React from 'react';
import PropTypes from 'prop-types';

import { withRouter } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

function NextChapterView({ currentChapter, chapters, history }) {
  const onClick = () => {
    if (!currentChapter) return;
    const indexOfCurrentChapter = chapters.map((e) => e.id).indexOf(currentChapter.id);

    if (indexOfCurrentChapter === chapters.length - 1) {
      return;
    }

    const nextChapter = chapters[indexOfCurrentChapter + 1];
    history.push(`?ref=${nextChapter.reference}`);
  };

  return (
    <Button variant="outline-dark" style={{ float: 'right' }} onClick={() => onClick()}>
      Neste
      <FontAwesomeIcon icon={faArrowRight} />
    </Button>
  );
}

NextChapterView.propTypes = {
  currentChapter: PropTypes.objectOf().isRequired,
  chapters: PropTypes.arrayOf().isRequired,
};

export default withRouter(NextChapterView);
