import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

export default class Search {
  static openSearch(keyword, orderBy, page, searchType) {
    return new Promise((resolve, reject) => {
      const url = `${API_URL}/search`;
      const params = {
        keyword, orderBy, page, searchType,
      };
      axios
        .get(url, { params })
        .then((result) => resolve(result.data))
        .catch((error) => reject(error));
    });
  }
}
