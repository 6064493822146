import React, { useContext } from 'react';
import { DataContext } from '../../Contexts/Contexts';

import Home from './Home';

export default function HomeContainer(props) {
  const { collections, books } = useContext(DataContext);

  return (
    <Home collections={collections} books={books} />
  );
}
