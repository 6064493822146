import React, { useState } from 'react';
import ReactMarkdown from 'react-markdown';
import 'github-markdown-css';

const path = require('./welcome.md');

export default function Welcome() {
  const [text, setText] = useState(null);

  fetch(path)
    .then((response) => response.text())
    .then((value) => setText(value));

  return (
    <div className="markdown-body" style={{ marginTop: '20px' }}>
      <ReactMarkdown source={text} />
    </div>

  );
}
