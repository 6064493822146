/* eslint-disable radix */
import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';

import {
  Container, Col, Row,
} from 'react-bootstrap';

import Toolbar from '../Toolbar/Toolbar';
import { BookViewContext } from '../../Contexts/Contexts';
import VerseArea from './Verses/VerseArea';
import ChaptersView from './Chapters/ChaptersView';

import PreviousChapterView from './Chapters/PreviousChapterView';
import NextChapterView from './Chapters/NextChapterView';
import ChapterControls from './Chapters/ChapterControls';

export default function BookView(props) {
  const {
    chapters, verses, book, targetChapter, targetVerse, toggleIsLinksVisible, isLinksVisible,
  } = useContext(BookViewContext);

  const scrollToTop = () => {
    const element = document.getElementById('navbar');
    if (element) {
      element.scrollIntoView();
    }
  };

  // Scroll to top when the chapter changes!
  useEffect(() => {
    scrollToTop();
  }, [targetChapter]);

  return (
    <div style={{ backgroundColor: 'white', height: '100%' }}>
      <Container>
        <Row style={{ marginTop: '10px' }}>
          <Col xs={6} sm={6} md={6} lg={6} xl={6}>
            <Toolbar onToggleReferences={toggleIsLinksVisible} />
          </Col>
          <Col xs={6} sm={6} md={6} lg={6} xl={6}>
            <div style={{ float: 'right' }}>
              <ChapterControls currentChapter={targetChapter} chapters={chapters} />
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <h2 style={{ marginBottom: '5px', marginTop: '10px' }}>{book.title}</h2>
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <ChaptersView chapters={chapters} currentChapter={targetChapter} />
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ marginTop: '10px' }}>
            <hr />
            <div>
              <h3>{targetChapter ? targetChapter.title : ''}</h3>
              <p>{targetChapter ? targetChapter.description : ''}</p>
            </div>
            <div style={{ marginTop: '20px' }}>
              <VerseArea isLinksVisible={isLinksVisible} verses={verses} targetVerse={targetVerse} />
            </div>
            <hr />
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ marginTop: '10px', marginBottom: '10px' }}>
            <PreviousChapterView currentChapter={targetChapter} chapters={chapters} />
            <NextChapterView currentChapter={targetChapter} chapters={chapters} />
          </Col>
        </Row>
      </Container>
    </div>
  );
}

BookView.propTypes = {};
