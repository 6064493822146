import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { OverlayTrigger, Badge, Popover } from 'react-bootstrap';

export default function VerseView(props) {
  const {
    verse, isLinksVisible,
  } = props;

  const rightStyle = { marginLeft: '35px' };
  const textStyle = { color: 'black', fontSize: '16px' };

  return (
    <div style={{ marginBottom: '10px' }} id={verse.id}>
      <ReferenceView verse={verse} />
      <div style={rightStyle}>
        <span style={textStyle}>{verse.text}</span>
        {isLinksVisible ? <LinksView links={verse.links} /> : null}
        <FootnotesView footnotes={verse.footnotes} />
      </div>
    </div>
  );
}

VerseView.propTypes = {
  verse: PropTypes.objectOf().isRequired,
  isLinksVisible: PropTypes.bool,
};

VerseView.defaultProps = {
  isLinksVisible: true,
};

function ReferenceView({ verse }) {
  const { index, collectionId, reference } = verse;
  const link = `/c/${collectionId}?ref=${reference}`;
  const style = { width: '35px', float: 'left' };

  return (
    <div style={style}>
      <Link to={link}>
        <span>{`${index + 1}.`}</span>
      </Link>
    </div>
  );
}

function LinksView({ links }) {
  const linkViews = links.map((e) => <LinkView link={e} />);
  return (
    <span>
      {links.length > 0 ? <br /> : null}
      {linkViews}
    </span>
  );
}

function FootnotesView({ footnotes }) {
  const footnoteViews = footnotes.map((e) => (
    <span>
      <span>{e.text}</span>
      <br />
    </span>
  ));

  return (
    <span>
      {footnotes.length > 0 ? <br /> : null}
      {footnoteViews}
    </span>
  );
}

function LinkView({ link }) {
  const {
    text, reference, linkedText, linkedCollectionId,
  } = link;

  const url = `/c/${linkedCollectionId}?ref=${reference}`;

  const popover = (
    <Popover>
      <Popover.Title as="h3">{reference}</Popover.Title>
      <Popover.Content>
        {linkedText}
      </Popover.Content>
    </Popover>
  );

  return (
    <OverlayTrigger placement="top" trigger="hover" overlay={popover}>
      <Link to={url}><Badge variant="secondary" style={{ marginLeft: '5px', marginTop: '5px' }}>{text}</Badge></Link>
    </OverlayTrigger>
  );
}
