import React from 'react';

import { Link, withRouter } from 'react-router-dom';
import {
  Navbar, Nav, Form, Container, Button,
} from 'react-bootstrap';

import './AppNavbar.css';

import { useAuth } from '../Auth/AuthProvider';

function AppNavbar(props) {
  const { history } = props;

  const { logout, isAuthenticated } = useAuth();

  return (
    <div className="navbar-container" id="navbar">
      <Navbar bg="dark" expand="lg" variant="dark">
        <Container>
          <Navbar.Brand href="/">Databibelen</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto">
              <Link to="/">
                <Nav.Link onClick={() => history.push('/')}>Bibelen</Nav.Link>
              </Link>
              <Link to="/search">
                <Nav.Link onClick={() => history.push('/search')}>Søk</Nav.Link>
              </Link>
              <Link to="/about">
                <Nav.Link onClick={() => history.push('/about')}>Om tjenesten</Nav.Link>
              </Link>
            </Nav>
            {isAuthenticated
              ? (
                <Form inline>
                  <Button variant="outline-light" onClick={() => logout()}>Logg ut</Button>
                </Form>
              ) : null}
          </Navbar.Collapse>
        </Container>
      </Navbar>

    </div>
  );
}

AppNavbar.propTypes = {

};

AppNavbar.defaultProps = {

};

export default withRouter((props) => <AppNavbar {...props} />);
