import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import VerseView from './VerseView';

import './VerseArea.css';

export default function VerseArea(props) {
  const { verses, isLinksVisible, targetVerse } = props;

  useEffect(() => {
    if (!targetVerse) return;
    const element = document.getElementById(targetVerse.id);
    if (element) {
      element.scrollIntoView();
      element.classList.add('highlight');
      setTimeout(() => { element.classList.remove('highlight'); }, 3000);
    }
  }, [targetVerse, verses]);

  const verseViews = verses.map((e) => <VerseView isLinksVisible={isLinksVisible} verse={e} />);
  return <div>{verseViews}</div>;
}

VerseArea.propTypes = {
  targetVerse: PropTypes.objectOf(),
  verses: PropTypes.arrayOf(PropTypes.object),
  isLinksVisible: PropTypes.bool,
};

VerseArea.defaultProps = {
  targetVerse: null,
  verses: [],
  isLinksVisible: true,
};
