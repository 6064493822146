import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'react-bootstrap';

export default function BrowserView({ items, onClick }) {
  const listItems = items.map((e) => (
    <tr onClick={() => onClick(e)}>
      <td>{e.title}</td>
      <td>{e.subtitle}</td>
    </tr>
  ));

  return (
    <Table striped bordered hover size="sm">
      <thead>
        <tr>
          <th>Tittel</th>
          <th>Referanse</th>
        </tr>
      </thead>
      <tbody>
        {listItems}
      </tbody>
    </Table>
  );
}

BrowserView.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  onClick: PropTypes.func.isRequired,
};
