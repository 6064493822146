import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { withRouter } from 'react-router-dom';

import { Button, ButtonGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink, faBook } from '@fortawesome/free-solid-svg-icons';
import BrowserContainer from '../Browser/BrowserContainer';


function Toolbar(props) {
  const { onToggleReferences } = props;

  const [isVisible, setIsVisible] = useState(false);

  const isDesktopSize = window.innerWidth > 700;

  return (
    <div id="toolbar">
      <ButtonGroup>
        <Button variant="outline-dark" onClick={() => setIsVisible(true)}>
          <FontAwesomeIcon icon={faBook} />
          {isDesktopSize ? 'Bøker' : null}
        </Button>
        <Button variant="outline-dark" onClick={() => onToggleReferences()}>
          <FontAwesomeIcon icon={faLink} />
          {isDesktopSize ? 'Henvisninger' : null}
        </Button>
      </ButtonGroup>
      <BrowserContainer style={{ width: '100%' }} isVisible={isVisible} onClose={() => setIsVisible(false)} />
    </div>
  );
}

export default withRouter((props) => <Toolbar {...props} />);

Toolbar.propTypes = {
  onToggleReferences: PropTypes.func.isRequired,
};
