import React, { useState, useEffect } from 'react';

import qs from 'qs';

import { SearchEngine } from '../../Api/index';
import './SearchView.css';
import SearchResultsView from './SearchResultsView';
import SearchBox from './SearchBox';
import SearchPagination from './SearchPagination';

export default function SearchView(props) {
  const { history } = props;

  const searchParams = getSearchParams(props);

  const {
    keyword, orderBy, searchType, page,
  } = searchParams;

  const [searchResult, setSearchResult] = useState(null);
  const [isSearching, setIsSearching] = useState(false);

  const search = () => {
    setIsSearching(true);
    SearchEngine.openSearch(keyword, orderBy, page, searchType).then((result) => {
      setSearchResult(result);
      setIsSearching(false);
    }).catch((error) => {
      setSearchResult(null);
      setIsSearching(false);
    });
  };

  const onSearch = (value) => {
    const params = { ...searchParams, ...value };
    history.push(`/search?${qs.stringify(params)}`);
  };

  const onPageChange = (pageIndex) => {
    const params = { ...searchParams, page: pageIndex };
    history.push(`/search?${qs.stringify(params)}`);
  };

  useEffect(() => {
    search();
  }, [keyword, orderBy, searchType, page]);

  const containerStyle = { marginTop: '10px', marginBottom: '10px' };

  return (
    <div style={{ height: '100%' }}>
      <div style={containerStyle}>
        <SearchBox {...props} isSearching={isSearching} onSearch={onSearch} />
      </div>
      <div style={containerStyle}>
        <SearchResultsView searchResult={searchResult} />
      </div>
      <div style={containerStyle}>
        <SearchPagination onPageChange={onPageChange} searchResult={searchResult} />
      </div>
    </div>
  );
}


function getSearchParams(props) {
  const searchParams = qs.parse(props.location.search, { ignoreQueryPrefix: true });
  return {
    keyword: '', orderBy: '', searchType: '', page: 0, ...searchParams,
  };
}
