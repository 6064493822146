import React from 'react';
import PropTypes from 'prop-types';

import { withRouter } from 'react-router-dom';

import { Modal } from 'react-bootstrap';
import Browser from './Browser';
import { DataContext } from '../../Contexts/Contexts';

function BrowserContainer(props) {
  const { isVisible, onClose } = props;

  return (
    <DataContext.Consumer>
      {(value) => (
        <Modal show={isVisible} onHide={onClose} size="lg">
          <Modal.Body>
            <Browser {...props} {...value} />
          </Modal.Body>
        </Modal>
      )}
    </DataContext.Consumer>
  );
}

BrowserContainer.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default withRouter(BrowserContainer);
