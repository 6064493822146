import React from 'react';
import PropTypes from 'prop-types';

import './SearchView.css';
import { Pagination } from 'react-bootstrap';

export default function SearchPagination(props) {
  const { searchResult, onPageChange } = props;

  if (!searchResult || searchResult.totalPages <= 1) return null;

  const containerStyle = { margin: '10px', overflowX: 'scroll' };
  const { page, totalPages } = searchResult;

  const paginationItems = [...Array(totalPages).keys()].map((e) => <Pagination.Item key={e} active={page === e}>{e}</Pagination.Item>);

  return (
    <div style={containerStyle}>
      <Pagination style={{ width: '100%' }} defaultActivePage={page} totalPages={totalPages} onPageChange={(e) => onPageChange(e.target.key)}>
        <Pagination.First />
        <Pagination.Prev />
        {paginationItems}
        <Pagination.Next />
        <Pagination.Last />
      </Pagination>
    </div>
  );
}

SearchPagination.propTypes = {
  onPageChange: PropTypes.func.isRequired,
  searchResult: PropTypes.shape({
    page: PropTypes.number.isRequired,
    totalPages: PropTypes.number.isRequired,
  }),
};

SearchPagination.defaultProps = {
  searchResult: null,
};
