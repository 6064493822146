import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

export default class Chapters {
  static getChapters(bookId) {
    return new Promise((resolve, reject) => {
      const url = `${API_URL}/books/${bookId}/chapters`;
      axios
        .get(url)
        .then((result) => resolve(result.data))
        .catch((error) => reject(error));
    });
  }
}
