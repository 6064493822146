import React from 'react';
import { Spinner } from 'react-bootstrap';


export default function Loading(props) {
  return (
    <div style={{ marginTop: '10px' }}>
      <h5>Laster innhold</h5>
      <Spinner animation="grow" variant="dark" />
      <Spinner animation="grow" variant="light" />
      <Spinner animation="grow" variant="dark" />
      <Spinner animation="grow" variant="light" />
      <Spinner animation="grow" variant="dark" />
      <Spinner animation="grow" variant="light" />
      <Spinner animation="grow" variant="dark" />
      <Spinner animation="grow" variant="light" />
    </div>
  );
}
