import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

export default class Collections {
  static getCollections() {
    return new Promise((resolve, reject) => {
      const url = `${API_URL}/collections`;

      axios
        .get(url)
        .then(result => resolve(result.data))
        .catch(error => reject(error));
    });
  }

  static getById(collectionId) {
    return new Promise((resolve, reject) => {
      const url = `${API_URL}/collections/${collectionId}`;

      axios
        .get(url)
        .then(result => resolve(result.data))
        .catch(error => reject(error));
    });
  }
}
