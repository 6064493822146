import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

export default class Verses {
  static getVerses(chapterId) {
    return new Promise((resolve, reject) => {
      const url = `${API_URL}/chapters/${chapterId}/verses`;

      axios
        .get(url)
        .then((result) => resolve(result.data))
        .catch((error) => reject(error));
    });
  }

  static getVersesRef(collectionId, ref) {
    return new Promise((resolve, reject) => {
      const url = `${API_URL}/collections/${collectionId}/chapters/${ref}`;

      axios
        .get(url)
        .then((result) => resolve(result.data))
        .catch((error) => reject(error));
    });
  }
}
